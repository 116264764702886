<!--
 * @Author: your name
 * @Date: 2021-01-28 15:27:27
 * @LastEditTime: 2021-01-29 17:36:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/views/newIndex/components/PcTopSweiper/index.vue
-->
<template>
  <div class="swiper_wap">
    <el-carousel :interval="3000" arrow="always" :autoplay="true">
      <el-carousel-item
        v-for="(item, index) in $t('WapTopMenuBarData')"
        :key="index"
      >
        <!-- <img
          class="carousel_img"
          src="@/assets/img/wapindex/Mask Group@2x.png"
        /> -->
        <img @click="goto(item.goto)" class="carousel_img" :src="item.imgUrl" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  methods: {
    goto(data) {
      if (data == "") {
        return;
      }
      if (data.indexOf("http") < 0) {
        this.$router.push(data);
      } else {
        window.location.href = data;
      }
    },
  },
};
</script>
<style scoped>
.swiper_wap >>> .el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 100px;
  margin: 0;
}
.swiper_wap >>> .el-carousel__container.el-carousel__container {
  height: calc(100vw * 37.5 / 37.5);
}
.swiper_wap >>> .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.swiper_wap >>> .el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.carousel_img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
